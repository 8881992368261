<template>
  <router-view />
</template>

<script>
import "@/assets/styles/main.scss";
export default {
  name: "App",
  mounted() {
    this.controlBodyClass();

    window.scroll(function () {
      if (window.screenTop() > 100) {
        document.getElementsByTagName("body").addClass("fixed-header");
      } else {
        document.getElementsByTagName("body").removeClass("fixed-header");
      }
    });
  },
  watch: {
    $route(to) {
      window.scrollTo(0, 0);
      var bodyElem = document.getElementsByTagName("body")[0];
      if (to.path == "/" || to.path == "/en/") {
        bodyElem.classList.add("home");
      } else {
        bodyElem.classList.remove("home");
      }

      if (to.path == "/" || to.path == "/en/" || to.path == "/en") {
        bodyElem.classList.add("dark");
      } else {
        bodyElem.classList.remove("dark");
      }
    },
  },
  methods: {
    controlBodyClass: () => {
      var lang = location.pathname.split("/")[1] == "en" ? "en" : "jp";
      var bodyElement = document.querySelector("body");
      if (lang == "en" && !bodyElement.classList.contains("eng")) {
        bodyElement.classList.add("eng");
        bodyElement.classList.remove("jp");
      } else {
        bodyElement.classList.remove("eng");
        bodyElement.classList.add("jp");
      }
    },
  },
};
</script>
