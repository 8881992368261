import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload';
import 'bootstrap';
import '@/assets/styles/main.scss';

const app = createApp(App)
// use router
app.use(VueLazyload, {
    preLoad: 1.3,
    // error: require('@/assets/images/error.png'),
    loading: require('@/assets/loading.svg'),
})
app.use(router)
app.mount('#app')

// createApp(App).mount('#app')
